var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flood-filter-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.pageHelpMessageOrDescription(_vm.module.model)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{attrs:{"title":_vm.backButtonTitle},on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('filter-setting-layout',{attrs:{"filter-model":_vm.module.model},scopedSlots:_vm._u([{key:"tab-main",fn:function(){return [_c('a-card',{attrs:{"title":_vm.$t('module_flood_section_messages_spam')}},[_c('time-unit-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'flood_message_interval',
              'hasAnchor': true,
              'min': 0,
              'max': _vm.StepUnit.Minute * 5,
              'units': [ _vm.StepUnit.Second, _vm.StepUnit.Minute ],
              'settingWrapper': {
                disabledValue: 0,
                hasFieldButton: true,
                customiseValue: 5
              },
              'disabled': _vm.disabled
            }
          }}}),(_vm.module.model.params.flood_message_interval > 0)?_c('nested-content',{staticClass:"mt-0"},[_c('number-input',{staticClass:"mb-0 mt-0",attrs:{"setup":{
              'func': _vm.newConfigInputSetup,
              'args': {
                'model': _vm.module.model.params,
                'key': 'flood_message_count',
                'hasAnchor': true,
                'min': 0,
                'disabled': _vm.disabled
              }
            }}})],1):_vm._e()],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('module_flood_section_messages_same')}},[_c('time-unit-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'flood_duplicate_control_interval',
              'hasAnchor': true,
              'min': 0,
              'settingWrapper': {
                disabledValue: 0,
                hasFieldButton: true,
                customiseValue: _vm.StepUnit.Minute * 5
              },
              'disabled': _vm.disabled
            }
          }}}),_c('select-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'chat_options',
              'options': _vm.floodOptions,
              'clearable': true,
              'multiple': true,
              'hasAnchor': true,
              'disabled': _vm.disabled
            }
          }}}),_c('select-input',{staticClass:"mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'user_options',
              'options': _vm.floodOptions,
              'clearable': true,
              'multiple': true,
              'hasAnchor': true,
              'disabled': _vm.disabled
            }
          }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('module_flood_section_words_same')}},[_c('select-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'flood_duplicate_words_multiply',
              'options': _vm.wordsMultiplyOptions,
              'clearable': false,
              'multiple': false,
              'disabled': _vm.disabled
            }
          }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('filter_title_advanced')}},[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'delete_recent_messages',
              'hasAnchor': true,
              'disabled': _vm.disabled
            }
          }}})],1),_c('filter-journal',{attrs:{"warn-field":_vm.module.model.journal_moderation_warn,"ban-field":_vm.module.model.journal_moderation_ban,"disabled":_vm.disabled},on:{"update:warnField":function($event){return _vm.$set(_vm.module.model, "journal_moderation_warn", $event)},"update:warn-field":function($event){return _vm.$set(_vm.module.model, "journal_moderation_warn", $event)},"update:banField":function($event){return _vm.$set(_vm.module.model, "journal_moderation_ban", $event)},"update:ban-field":function($event){return _vm.$set(_vm.module.model, "journal_moderation_ban", $event)}}}),_c('filter-extra-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true},{key:"tab-regular",fn:function(){return [_c('filter-common-settings',{attrs:{"fields-prefix":_vm.module.model.snakeCaseFilterType,"disabled":_vm.disabled,"custom-punish-builders":_vm.module.customPunishBuilders},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }